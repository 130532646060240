
import './index.css';
import App from './App';
import { loadConfig } from './configLoader';

import React from 'react';
import ReactDOM from 'react-dom/client';

async function initializeApp() {
  try {
    await loadConfig();
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Failed to load config:', error);
    // Here you might want to render an error message to the user
  }
}

initializeApp();
