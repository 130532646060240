import { useState, useEffect, useCallback } from 'react';
import AWS from 'aws-sdk';
import { FOLDERS } from '../constants';

function useFileLoader(bucketName) {
    const [files, setFiles] = useState({
        applicationLog: [],
        gaugeLog: []
    });
    const [error, setError] = useState(null);

    const loadFiles = useCallback(() => {
        const s3 = new AWS.S3();
        const loadFolder = (folder, fileType) => {
            s3.listObjectsV2({ Bucket: bucketName, Prefix: folder }, (err, data) => {
                if (err) {
                    console.error(`Error listing objects in ${folder}:`, err);
                    setError("Failed to load files. Please try again later.");
                    return;
                }
                setFiles(prevFiles => ({
                    ...prevFiles,
                    [fileType]: data.Contents.filter(item => item.Key !== folder)
                }));
                setError(null);
            });
        };

        loadFolder(FOLDERS.APPLICATION_LOG, 'applicationLog');
        loadFolder(FOLDERS.GAUGE_LOG, 'Reports');
    }, [bucketName]);

    useEffect(() => {
        if (bucketName) {
            loadFiles();
        } else {
            setError('AWS configuration is not available. Please check your setup.');
        }
    }, [bucketName, loadFiles]);

    return { files, error, loadFiles };
}

export default useFileLoader;