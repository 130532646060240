import React, { useState, useMemo } from 'react';
import useFileLoader from '../../hooks/useFileLoader';
import useFileContent from '../../hooks/useFileContent';
import FileList from './FileList';
import FileContent from './FileContent';
import FullContentModal from './FullContentModal';
import './FileViewer.css';

function FileViewer() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fullCellContent, setFullCellContent] = useState('');
  const [showFullContent, setShowFullContent] = useState(false);

  const bucketName = window.appConfig.BUCKET_NAME;
  const { files, error: loadError } = useFileLoader(bucketName);
  const { content, error: contentError } = useFileContent(bucketName, selectedFile);

  const error = loadError || contentError;

  const handleFileSelect = (key) => {
    setSelectedFile(key);
  };

  const handleViewFullContent = (content) => {
    setFullCellContent(content);
    setShowFullContent(true);
  };

  const applicationLogFileList = useMemo(() => (
    <FileList
      files={files.applicationLog}
      selectedFile={selectedFile}
      onFileSelect={handleFileSelect}
    />
  ), [files.applicationLog, selectedFile]);

  const gaugeLogFileList = useMemo(() => (
    <FileList
      files={files.gaugeLog}
      selectedFile={selectedFile}
      onFileSelect={handleFileSelect}
    />
  ), [files.gaugeLog, selectedFile]);

  return (
    <div className="file-viewer">
      {error && <div className="error-message">{error}</div>}
      <div className="file-list">
        <h2>Active Users</h2>
        {applicationLogFileList}
      </div>
      <FileContent content={content} onViewFullContent={handleViewFullContent} />
      <FullContentModal
        show={showFullContent}
        content={fullCellContent}
        onClose={() => setShowFullContent(false)}
      />
    </div>
  );
}

export default FileViewer;