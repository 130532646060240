import './App.css';
import Login from './components/Auth/Login';
import NewPassword from './components/Auth/NewPassword';
import FileViewer from './components/FileViewer/FileViewer';

import AWS from 'aws-sdk';
import React, { useState, useEffect } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [needNewPassword, setNeedNewPassword] = useState(false);
  const [error, setError] = useState('');
  const [userPool, setUserPool] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [requiredAttributes, setRequiredAttributes] = useState({});
  const [awsConfigured, setAwsConfigured] = useState(false);


  useEffect(() => {
    if (window.appConfig) {
      try {
        const pool = new CognitoUserPool({
          UserPoolId: window.appConfig.USER_POOL_ID,
          ClientId: window.appConfig.APP_CLIENT_ID
        });
        setUserPool(pool);

        const currentUser = pool.getCurrentUser();
        if (currentUser) {
          currentUser.getSession((err, session) => {
            if (err) {
              console.log("Session error, user might be logged out:", err);
              setIsLoggedIn(false);
              return;
            }
            if (session.isValid()) {
              setIsLoggedIn(true);
              initializeAWS(session);
            } else {
              setIsLoggedIn(false);
            }
          });
        }
      } catch (err) {
        console.error("Error initializing Cognito User Pool:", err);
        setError("Failed to initialize the application. Please check your configuration.");
      }
    } else {
      setError("Configuration not loaded. Please refresh the page.");
    }
  }, []);

  const handleLoginSuccess = (session, user) => {
    setIsLoggedIn(true);
    setCognitoUser(user);
    initializeAWS(session);
  };


  const handleNewPasswordRequired = (user, requiredAttributes) => {
    setNeedNewPassword(true);
    setCognitoUser(user);
    setRequiredAttributes(requiredAttributes);
  };


  const handleNewPasswordSuccess = (session) => {
    setNeedNewPassword(false);
    setIsLoggedIn(true);
    initializeAWS(session);
  };

  const initializeAWS = (session) => {
    const idToken = session.getIdToken().getJwtToken();
    AWS.config.region = window.appConfig.REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: window.appConfig.IDENTITY_POOL_ID,
      Logins: {
        [`cognito-idp.${window.appConfig.REGION}.amazonaws.com/${window.appConfig.USER_POOL_ID}`]: idToken
      }
    });

    // Ensure credentials are refreshed
    AWS.config.credentials.refresh((error) => {
      if (error) {
        console.error('Error refreshing AWS credentials:', error);
        setError('Failed to initialize AWS. Please try logging in again.');
      } else {
        setAwsConfigured(true);
      }
    });
  };


  const handleLogout = () => {

    if (cognitoUser) {
      cognitoUser.signOut();
    }

    // Clear all auth-related state
    setIsLoggedIn(false);
    setNeedNewPassword(false);
    setCognitoUser(null);
    setAwsConfigured(false);

    // Clear AWS credentials
    if (AWS.config.credentials) {
      AWS.config.credentials.clearCachedId();
    }

    // Clear any auth tokens from local storage
    localStorage.removeItem('CognitoIdentityServiceProvider.' + userPool.getClientId() + '.LastAuthUser');
    localStorage.removeItem('CognitoIdentityServiceProvider.' + userPool.getClientId() + '.accessToken');
    localStorage.removeItem('CognitoIdentityServiceProvider.' + userPool.getClientId() + '.idToken');
    localStorage.removeItem('CognitoIdentityServiceProvider.' + userPool.getClientId() + '.refreshToken');
    console.log("Local storage cleared");  // Add this for debugging

    // Force a page reload to ensure all states are reset
    window.location.reload();
  };

  if (needNewPassword) {
    return <NewPassword
      onSuccess={handleNewPasswordSuccess}
      onError={setError}
      cognitoUser={cognitoUser}
      requiredAttributes={requiredAttributes}
    />;
  }

  if (error) {
    return <div className="error-container">{error}</div>;
  }

  if (needNewPassword) {
    return <NewPassword onSuccess={handleNewPasswordSuccess} onError={setError} cognitoUser={cognitoUser} />;
  }

  if (!isLoggedIn) {
    return <Login onSuccess={handleLoginSuccess} onNewPasswordRequired={handleNewPasswordRequired} onError={setError} userPool={userPool} />;
  }

  return (
    <div className="app-container">
      <header className="app-header">
        <h1>Dashboard</h1>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </header>
      <main className="app-main">
        {awsConfigured ? <FileViewer /> : <div className="loading">Loading AWS configuration...</div>}
      </main>
    </div>
  );
}

export default App;