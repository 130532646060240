import React, { useState } from 'react';
import './NewPassword.css'; // Make sure to create this file with the CSS provided above

function NewPassword({ onSuccess, onError, cognitoUser }) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    if (!cognitoUser) {
      setError('User not found. Please try logging in again.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    const userAttributes = {
      name: name,
    };

    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: onSuccess,
      onFailure: (err) => setError('Failed to set new password: ' + err.message)
    });
  };

  return (
    <div className="new-password-container">
      <form className="new-password-form" onSubmit={handleSubmit}>
        <h2>Set New Password</h2>
        <input
          type="text"
          placeholder="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit">Set New Password</button>
        {error && <p className="new-password-error">{error}</p>}
      </form>
    </div>
  );
}

export default NewPassword;