import { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

function useFileContent(bucketName, selectedFile) {
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!selectedFile) return;

        const s3 = new AWS.S3();
        s3.getObject({ Bucket: bucketName, Key: selectedFile }, (err, data) => {
            if (err) {
                console.error("Error getting object:", err);
                setError("Failed to load file. Please try again later.");
                setContent(null);
                return;
            }
            setError(null);

            if (selectedFile.toLowerCase().endsWith('.png')) {
                const blob = new Blob([data.Body], { type: 'image/png' });
                setContent({ type: 'image', data: URL.createObjectURL(blob) });
            } else if (selectedFile.toLowerCase().endsWith('.csv')) {
                const csvContent = new TextDecoder().decode(data.Body);
                setContent({ type: 'csv', data: csvContent });
            } else {
                setContent({ type: 'unsupported' });
            }
        });
    }, [bucketName, selectedFile]);

    return { content, error };
}

export default useFileContent;