import Papa from 'papaparse';

function FileContent({ content, onViewFullContent }) {

    if (!content) return null;


    if (content.type === 'csv') {
        const parsedCsv = Papa.parse(content.data);

        if (!parsedCsv.data || parsedCsv.data.length === 0) {
            return <p>No data available in the CSV file.</p>;
        }

        const headers = parsedCsv.data[0];
        const rows = parsedCsv.data.slice(1);

        return (
            <div className="table-container">
                <table className="csv-table">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => {
                                    const cellContent = cell || '';
                                    const truncatedCell = cellContent.length > 100 ? cellContent.substring(0, 100) + '...' : cellContent;
                                    return (
                                        <td key={cellIndex}>
                                            <div className="cell-content">
                                                <span className="truncated-content">{truncatedCell}</span>
                                                {cellContent.length > 100 && (
                                                    <button
                                                        className="view-full-btn"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onViewFullContent(cellContent);
                                                        }}
                                                    >
                                                        View Full
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return <p>Unsupported file type</p>;
}

export default FileContent;