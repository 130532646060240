import React from 'react';

function FileList({ files, selectedFile, onFileSelect }) {
    return (
        <ul>
            {files.map((file) => (
                <li
                    key={file.Key}
                    onClick={() => onFileSelect(file.Key)}
                    className={selectedFile === file.Key ? 'selected' : ''}
                >
                    {file.Key.split('/').pop()}
                </li>
            ))}
        </ul>
    );
}

export default FileList;