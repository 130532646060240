export function loadConfig() {
    return new Promise((resolve, reject) => {
      if (window.appConfig) {
        resolve(window.appConfig);
      } else {
        const script = document.createElement('script');
        script.src = `${process.env.PUBLIC_URL}/config.js`;
        script.onload = () => {
          if (window.appConfig) {
            resolve(window.appConfig);
          } else {
            reject(new Error('Config failed to load'));
          }
        };
        script.onerror = () => reject(new Error('Could not load config file'));
        document.head.appendChild(script);
      }
    });
  }